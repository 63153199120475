import React from "react";
import Header from "./header";
import Language from "./Language";
import Speak from "./../../components/Speak";
function index({location}) {
  return (
    <div>
      <Header />
      <Language />
      <Speak  location={location}/>
    </div>
  );
}

export default index;
